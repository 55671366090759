import React from "react";
import { Link } from "gatsby";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";

const BusinessServiceSidebar = ({ activeLink }) => {
  const practiceAreas = [
    {
      name: "Estate Planning",
      href: "/estate-planning-attorney-san-diego/",
    },
    {
      name: "Probate",
      href: "/probate-attorney-san-diego/",
    },
    {
      name: "Trust Administration",
      href: "/trust-administration-lawyer/",
    },
    {
      name: "Conservatorship",
      href: "/conservatorship-lawyer/",
    },
    {
      name: "Guardianship",
      href: "/guardianship-lawyer/",
    },
    {
      name: "Special Needs Trust",
      href: "/special-needs-trust-lawyer/",
    },
    {
      name: "Probate Litigation",
      href: "/probate-litigation-lawyer/",
    },
    {
      name: "Trust Litigation",
      href: "/trust-estate-litigation-lawyer/",
    },
    {
      name: "Trust & Estate Mediation",
      href: "/trust-estate-mediation/",
    },
    {
      name: "Property Transfers",
      href: "/parent-grandparent-child-exclusions/",
    },
    {
      name: "Estate Tax Returns",
      href: "/estate-tax-returns/",
    },
    {
      name: "Accountings",
      href: "/trust-accountings/",
    },
    {
      name: "Agent Representation",
      href: "/agent-representation/",
    },
  ];

  return (
    <div className="sticky top-24">
      <div className="mb-5 font-display text-xl text-typography-heading">
        Practice Areas
      </div>
      <ul className="space-y-2">
        {practiceAreas.map((item, i) => (
          <li key={item.name} className="whitespace-nowrap">
            <Link
              to={item.href}
              className={`text-sm font-semibold no-underline hover:text-seafoam ${
                activeLink === i ? "text-seafoam" : "text-seafoam/60"
              }`}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>

      <div className="ml-auto mt-14 w-[298px] rounded-3xl border border-seafoam px-4 py-6">
        <div className="mb-4 font-display text-2xl font-semibold text-typography-heading">
          Schedule a Consultation
        </div>
        <p className="font-display text-lg">
          When you need a San Diego estate planning attorney to help you put
          everything in order, Brierton, Jones & Jones, LLP is here for you.
        </p>

        <div className="space-y-4">
          <ButtonGhost
            href="tel:619-485-5394"
            text="(619) 485-5394"
            className="w-full"
          />
          <ButtonSolid
            modal="modal-contact"
            text="Send a Message"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessServiceSidebar;
