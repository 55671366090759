import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Impartiality",
      content: (
        <>
          <p>
            Mediators are trained professionals who practice impartiality (they
            do not favor one party over another).
          </p>
        </>
      ),
    },
    {
      title: "Impasse",
      content: (
        <>
          <p>
            An impasse is when a compromise is unable to be met. At this point,
            litigation may be necessary to resolve the dispute.
          </p>
        </>
      ),
    },
    {
      title: "Reframing",
      content: (
        <>
          <p>
            A mediator may use this tool to get both parties to take a more
            objective view of a dispute. The mediator will listen to both sides,
            then re-tell their understanding of the situation (reframing it in
            their own words). Reframing allows the parties to see the situation
            more objectively.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Trust and Estate Mediation | Brierton, Jones & Jones"
        description="When you need a trust and estate mediation lawyer in San Diego, Brierton, Jones & Jones, LLP is here to deliver. Learn more and schedule a consultation today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pb-6 pt-12 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  BEFORE YOU GO TO COURT
                </div>
                <h1>San Diego Trust and Estate Mediation Attorney</h1>
                <p>
                  Disputes over trust and estate matters can be difficult to
                  resolve because of the close personal connections between
                  parties.{" "}
                  <a href="https://briertonjones.com/trust-estate-litigation-lawyer/">
                    Litigation
                  </a>{" "}
                  may be necessary in some cases, but trust and estate mediation
                  is an alternative that some families may wish to seek first.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/9.0 Trust _ Estate Mediation/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>What Happens in Mediation?</h2>
                <p>
                  In mediation, the parties involved in a dispute agree to
                  informal negotiations through a third party (a mediator). The
                  mediator attempts to find common ground and may offer an
                  impartial assessment of the parties’ legal positions. These
                  discussions are non-binding and if no agreement can be
                  reached, then litigation remains an option.
                </p>
              </div>

              <div className="mb-12 md:mb-18">
                <h2>The Advantages of Mediation</h2>
                <p>
                  Mediation is more private, and less adversarial, than
                  litigation. And for many families who are trying to preserve
                  their relationships, mediation is preferable. Reaching a
                  mutually acceptable resolution, rather than having to accept a
                  judge’s order, can benefit both sides. Mediation is also
                  generally more cost effective than litigation.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                  If you’re considering hiring a trust and estate mediation
                  attorney to hopefully avoid going to court, here are some of
                  the common terms to familiarize yourself with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={8} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="Meet with one of our San Diego trust and estate mediation attorneys if you have questions about the process or want more information." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Facebook/09 Trust _ Estate Mediation.jpg"
      }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: {
        eq: "Global/Open Graphs/Twitter/09 Trust _ Estate Mediation.jpg"
      }
    ) {
      publicURL
    }
  }
`;

export default Page;
